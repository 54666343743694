import(/* webpackMode: "eager", webpackExports: ["default"] */ "/vercel/path0/app/_core/components/AboutAsset/AboutAsset.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/vercel/path0/app/_core/components/AssetLogo/AssetLogo.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/vercel/path0/app/_core/components/AssetsList/AssetListItem.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/vercel/path0/app/_core/components/AssetsList/AssetsList.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/vercel/path0/app/_core/components/AssetsSearch/AssetsSearch.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/vercel/path0/app/_core/components/AssetsSearch/AssetsSearchButton.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/vercel/path0/app/_core/components/AssetsSearch/AssetsSearchModal.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/vercel/path0/app/_core/components/AssetsSearch/TopPerformersCard.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/vercel/path0/app/_core/components/AssetsSearch/TopPerformersCards.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/vercel/path0/app/_core/components/AssetUnavailable/AssetUnavailable.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/vercel/path0/app/_core/components/DefaultErrorMessage/DefaultErrorMessage.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/vercel/path0/app/_core/components/DevConsole/DevConsole.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/vercel/path0/app/_core/components/Education/AvailableTokenRow.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/vercel/path0/app/_core/components/Education/EducationCard.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/vercel/path0/app/_core/components/Education/LearnSection.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/vercel/path0/app/_core/components/InvestmentAsset/InvestmentAsset.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/vercel/path0/app/_core/components/InvestmentCTA/InvestmentCTA.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/vercel/path0/app/_core/components/InvestmentsList/InvestmentCard.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/vercel/path0/app/_core/components/InvestmentsList/InvestmentsList.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/vercel/path0/app/_core/components/InvestmentStat/InvestmentStat.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/vercel/path0/app/_core/components/InvestModal/InvestAssetsSearch.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/vercel/path0/app/_core/components/InvestModal/InvestModal.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/vercel/path0/app/_core/components/LatestTrade/LatestTrade.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/vercel/path0/app/_core/components/LatestTransactions/LatestTransactions.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/vercel/path0/app/_core/components/Onboarding/CreateAccountCard.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/vercel/path0/app/_core/components/Onboarding/IntroductionCard.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/vercel/path0/app/_core/components/Onboarding/Modal/Modal.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/vercel/path0/app/_core/components/Onboarding/OnboardingStep.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/vercel/path0/app/_core/components/PageLayout/PageLayout.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/vercel/path0/app/_core/components/PerformanceOverTime/Existing.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/vercel/path0/app/_core/components/PerformanceOverTime/New.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/vercel/path0/app/_core/components/Providers/Providers.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["GlobalStoreContext"] */ "/vercel/path0/app/_core/components/Providers/StoreProvider.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/vercel/path0/app/_core/components/ReportRow/ReportRow.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["LabelTd","ValueTd"] */ "/vercel/path0/app/_core/components/SingleTransactionTable/SingleTransactionTable.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/vercel/path0/app/_core/components/TimeRangeSelector/TimeRangeSelector.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/vercel/path0/app/_core/components/TransactionHistory/ActiveFilters.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/vercel/path0/app/_core/components/TransactionHistory/AssetTransactionHistory.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/vercel/path0/app/_core/components/TransactionHistory/TransactionAccordion.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/vercel/path0/app/_core/components/TransactionHistory/TransactionAccordionList.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/vercel/path0/app/_core/components/TransactionHistory/TransactionHistoryFilterModal.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/vercel/path0/app/_core/components/TransactionHistory/TransactionHistoryTable.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/vercel/path0/app/_core/components/TransactionStatus/TransactionStatus.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/vercel/path0/app/_core/components/TrendIndicator/TrendIndicator.tsx");
;
import(/* webpackMode: "eager" */ "/vercel/path0/node_modules/@chakra-ui/accordion/dist/index.mjs");
;
import(/* webpackMode: "eager" */ "/vercel/path0/node_modules/@chakra-ui/alert/dist/index.mjs");
;
import(/* webpackMode: "eager" */ "/vercel/path0/node_modules/@chakra-ui/avatar/dist/index.mjs");
;
import(/* webpackMode: "eager" */ "/vercel/path0/node_modules/@chakra-ui/breadcrumb/dist/index.mjs");
;
import(/* webpackMode: "eager" */ "/vercel/path0/node_modules/@chakra-ui/button/dist/index.mjs");
;
import(/* webpackMode: "eager" */ "/vercel/path0/node_modules/@chakra-ui/card/dist/index.mjs");
;
import(/* webpackMode: "eager" */ "/vercel/path0/node_modules/@chakra-ui/checkbox/dist/index.mjs");
;
import(/* webpackMode: "eager" */ "/vercel/path0/node_modules/@chakra-ui/close-button/dist/index.mjs");
;
import(/* webpackMode: "eager" */ "/vercel/path0/node_modules/@chakra-ui/color-mode/dist/index.mjs");
;
import(/* webpackMode: "eager" */ "/vercel/path0/node_modules/@chakra-ui/control-box/dist/index.mjs");
;
import(/* webpackMode: "eager" */ "/vercel/path0/node_modules/@chakra-ui/counter/dist/index.mjs");
;
import(/* webpackMode: "eager" */ "/vercel/path0/node_modules/@chakra-ui/css-reset/dist/index.mjs");
;
import(/* webpackMode: "eager" */ "/vercel/path0/node_modules/@chakra-ui/editable/dist/index.mjs");
;
import(/* webpackMode: "eager" */ "/vercel/path0/node_modules/@chakra-ui/focus-lock/dist/index.mjs");
;
import(/* webpackMode: "eager" */ "/vercel/path0/node_modules/@chakra-ui/form-control/dist/index.mjs");
;
import(/* webpackMode: "eager" */ "/vercel/path0/node_modules/@chakra-ui/hooks/dist/index.mjs");
;
import(/* webpackMode: "eager" */ "/vercel/path0/node_modules/@chakra-ui/icon/dist/index.mjs");
;
import(/* webpackMode: "eager" */ "/vercel/path0/node_modules/@chakra-ui/image/dist/index.mjs");
;
import(/* webpackMode: "eager" */ "/vercel/path0/node_modules/@chakra-ui/input/dist/index.mjs");
;
import(/* webpackMode: "eager" */ "/vercel/path0/node_modules/@chakra-ui/layout/dist/index.mjs");
;
import(/* webpackMode: "eager" */ "/vercel/path0/node_modules/@chakra-ui/media-query/dist/index.mjs");
;
import(/* webpackMode: "eager" */ "/vercel/path0/node_modules/@chakra-ui/menu/dist/index.mjs");
;
import(/* webpackMode: "eager" */ "/vercel/path0/node_modules/@chakra-ui/modal/dist/index.mjs");
;
import(/* webpackMode: "eager" */ "/vercel/path0/node_modules/@chakra-ui/number-input/dist/index.mjs");
;
import(/* webpackMode: "eager" */ "/vercel/path0/node_modules/@chakra-ui/pin-input/dist/index.mjs");
;
import(/* webpackMode: "eager" */ "/vercel/path0/node_modules/@chakra-ui/popover/dist/index.mjs");
;
import(/* webpackMode: "eager" */ "/vercel/path0/node_modules/@chakra-ui/popper/dist/index.mjs");
;
import(/* webpackMode: "eager" */ "/vercel/path0/node_modules/@chakra-ui/portal/dist/index.mjs");
;
import(/* webpackMode: "eager" */ "/vercel/path0/node_modules/@chakra-ui/progress/dist/index.mjs");
;
import(/* webpackMode: "eager" */ "/vercel/path0/node_modules/@chakra-ui/radio/dist/index.mjs");
;
import(/* webpackMode: "eager" */ "/vercel/path0/node_modules/@chakra-ui/react-env/dist/index.mjs");
;
import(/* webpackMode: "eager", webpackExports: ["ChakraBaseProvider","ChakraProvider"] */ "/vercel/path0/node_modules/@chakra-ui/react/dist/chunk-QAITB7GG.mjs");
;
import(/* webpackMode: "eager" */ "/vercel/path0/node_modules/@chakra-ui/select/dist/index.mjs");
;
import(/* webpackMode: "eager" */ "/vercel/path0/node_modules/@chakra-ui/skeleton/dist/index.mjs");
;
import(/* webpackMode: "eager" */ "/vercel/path0/node_modules/@chakra-ui/skip-nav/dist/index.mjs");
;
import(/* webpackMode: "eager" */ "/vercel/path0/node_modules/@chakra-ui/slider/dist/index.mjs");
;
import(/* webpackMode: "eager" */ "/vercel/path0/node_modules/@chakra-ui/spinner/dist/index.mjs");
;
import(/* webpackMode: "eager" */ "/vercel/path0/node_modules/@chakra-ui/stat/dist/index.mjs");
;
import(/* webpackMode: "eager" */ "/vercel/path0/node_modules/@chakra-ui/stepper/dist/index.mjs");
;
import(/* webpackMode: "eager" */ "/vercel/path0/node_modules/@chakra-ui/switch/dist/index.mjs");
;
import(/* webpackMode: "eager", webpackExports: ["styled","toCSSObject"] */ "/vercel/path0/node_modules/@chakra-ui/system/dist/chunk-5PL47M24.mjs");
;
import(/* webpackMode: "eager", webpackExports: ["getToken","useChakra","useToken"] */ "/vercel/path0/node_modules/@chakra-ui/system/dist/chunk-7FWEOSAE.mjs");
;
import(/* webpackMode: "eager", webpackExports: ["useComponentStyles__unstable","useMultiStyleConfig","useStyleConfig"] */ "/vercel/path0/node_modules/@chakra-ui/system/dist/chunk-DMO4EI7P.mjs");
;
import(/* webpackMode: "eager", webpackExports: ["shouldForwardProp"] */ "/vercel/path0/node_modules/@chakra-ui/system/dist/chunk-FDQH4LQI.mjs");
;
import(/* webpackMode: "eager" */ "/vercel/path0/node_modules/@chakra-ui/system/dist/chunk-I77ZCDZJ.mjs");
;
import(/* webpackMode: "eager", webpackExports: ["CSSVars","GlobalStyle","StylesProvider","ThemeProvider","createStylesContext","useStyles"] */ "/vercel/path0/node_modules/@chakra-ui/system/dist/chunk-MFVQSVQB.mjs");
;
import(/* webpackMode: "eager", webpackExports: ["useTheme"] */ "/vercel/path0/node_modules/@chakra-ui/system/dist/chunk-UIGT7YZF.mjs");
;
import(/* webpackMode: "eager", webpackExports: ["chakra"] */ "/vercel/path0/node_modules/@chakra-ui/system/dist/chunk-ZHQNHOQS.mjs");
;
import(/* webpackMode: "eager", webpackExports: ["forwardRef"] */ "/vercel/path0/node_modules/@chakra-ui/system/dist/chunk-ZJJGQIVY.mjs");
;
import(/* webpackMode: "eager" */ "/vercel/path0/node_modules/@chakra-ui/table/dist/index.mjs");
;
import(/* webpackMode: "eager" */ "/vercel/path0/node_modules/@chakra-ui/tabs/dist/index.mjs");
;
import(/* webpackMode: "eager" */ "/vercel/path0/node_modules/@chakra-ui/tag/dist/index.mjs");
;
import(/* webpackMode: "eager" */ "/vercel/path0/node_modules/@chakra-ui/textarea/dist/index.mjs");
;
import(/* webpackMode: "eager" */ "/vercel/path0/node_modules/@chakra-ui/toast/dist/index.mjs");
;
import(/* webpackMode: "eager" */ "/vercel/path0/node_modules/@chakra-ui/tooltip/dist/index.mjs");
;
import(/* webpackMode: "eager" */ "/vercel/path0/node_modules/@chakra-ui/transition/dist/index.mjs");
;
import(/* webpackMode: "eager" */ "/vercel/path0/node_modules/@chakra-ui/visually-hidden/dist/index.mjs");
;
import(/* webpackMode: "eager", webpackExports: ["SpeedInsights"] */ "/vercel/path0/node_modules/@vercel/speed-insights/dist/next/index.mjs");
